@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap');


$font-family: 'Roboto', sans-serif;
$font-family-promo: 'Roboto', sans-serif;


$accent-color: #f5b21a;
$background-color: #003366;

$bonus-slider-background: transparent;