.bonus_wrapper{
    justify-content: end;

    .bonus_container{
        //margin-right: 70px;
        img{
            height: 130% !important;
        }
    }
}
.btn.bonus {
    margin-top: 15px;
}
.search_icon{
    @include recolor(white)
}
.search_icon:hover{
    @include recolor(#f5b21a)
}
.jackpot.name{
    color: white;
}
.jackpot.value{
    color: #f5b21a;
}
.btn.header {
    color: white;
}
.btn.header.st {
    color: #f5b21a;
}

.lp-bg {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    left: 0;
    z-index: inherit;
    top: 10%;
    width: 100vw;
    height: 75vh;
    animation: flare;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}
.bonus_slider{
    background-color: #003366;
    @media screen and (max-width: 600px) {
        .bonus_wrapper .bonus_container img {
            height: 100% !important;
        }
        .carousel-item{
            width: 100% !important;
        }
        .carousel-initialized{
            padding: 0 !important;
        }
        .bonus_wrapper{
            justify-content: center;
            //background-size: cover !important;
            //background-position-x: -350px !important;
            .bonus_container{
                margin-right: 0px;
            }
        }
        .btn.bonus {
            margin-top:0px;
            height: initial;
        }

    }
}
body {
    //background: url(http://cdn.casinocontroller.com/downloads/clientfiles/FatBet/lobby_bg1.jpg) !important;
}



.carousel-initialized {
    padding: 0 !important;
}

.header-logo {
    height: 80%;
}

